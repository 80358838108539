import config from '../../OEMConfig';

export const BASE_URL = config().api_host;
export const COMMON_INFO = '/common-info';
export const LOGIN = '/login';
export const FORGOT = '/forgot-password';
export const RESET = '/reset-password';
export const SUCCESS = '/success';
export const LOGOUT = '/logout';
export const INQUIRY = '/inquiries';
export const RECOGNITION = '/recognition';
export const NOTIFICATION = '/notification_settings';
export const EMAIL_SUCCESS = '/email-success';
export const USERS = '/users';
export const IG_REPORT_ACCESS = (id) =>
  `${USERS}/${id}/check-ig-report-notification-access`;
export const USERS_NOTIFICATION_SETTINGS = (id) =>
  `${USERS}/${id}/notification_settings`;
export const LOCATIONS = '/locations';
export const LOCATION_INFO_BY_LOCATION_ID = (id) =>
  `${LOCATIONS}/${id}/location-information-by-location`;
export const LOCATION_LIST = '/locationList';
export const MENU_CATEGORIES = '/menu_categories';
export const CATEGORIES_BY_LOCATION = '/categories-by-location';
export const MENUS_BY_CATEGORY = '/menus-by-category';
export const UPDATE_MENUS = '/translate-all-menus';
export const MENUS = '/menus';
export const MENUS_ALL = '/menus/all';
export const MENU_TRANSLATION_URL_ENCRYPTION = (id) =>
  `locations/${id}/menu/translation/encrypt`;
export const TRANSLATE_MENU_MANUAL = (id, lang) =>
  `locations/${id}/menus/manual-translation/${lang}`;
export const DEFAULT_LANGUAGES = (id) => `locations/${id}/default/languages`;
export const SERVICES = '/services';
export const PRODUCTS = '/products';
export const CONSULTING = '/consulting';
export const MENUS_CSV_DOWNLOAD = (id) => `${MENUS}/${id}/csv-download`;
export const MENUS_CSV_UPLOAD = 'menus/csv-upload';
export const INSTAGRAM_CONSULT_CONTACT = '/instagram-consult/contacts';
export const INSTAGRAM_CONSULT_CONTACT_REPLY =
  '/instagram-consult/contacts/replies';
export const INSTAGRAM_CONSULT_CONTACT_REPLY_BY_CONTACT = (id) =>
  `/instagram-consult/contacts/${id}/replies`;
export const INSTAGRAM_CONSULT_CONTACT_FILES_BY_CONTACT = (id) =>
  `/instagram-consult/contacts/${id}/link-files`;
export const INSTAGRAM_CONSULT_FILE = '/instagram-consult/files';
export const INSTAGRAM_CONSULT_FILE_SCHEDULE_DATE =
  '/instagram-consult/file-scheduledate';
export const INSTAGRAM_CONSULT_FILE_DELETE = '/instagram-consult/files-delete';
export const INSTAGRAM_CONSULT_FILE_UPDATE_STATUS =
  '/instagram-consult/files-update-status';
export const INSTAGRAM_CONSULT_SAMPLE_FILE = '/instagram-consult/sample-files';
export const INSTAGRAM_CONSULT_CONTACT_BY_LOCATION = (id) =>
  `${LOCATIONS}/${id}/instagram-consult/contacts`;
export const INSTAGRAM_CONSULT_FILE_BY_LOCATION = (id) =>
  `${LOCATIONS}/${id}/instagram-consult/files`;
export const INSTAGRAM_CONSULT_FOLDER_BY_LOCATION = (id) =>
  `${LOCATIONS}/${id}/instagram-consult/folders`;
export const INSTAGRAM_CONSULT_MOVE_FOLDERS = `/instagram-consult/folders-move`;
export const INDUSTRIES = '/industries-list-by-location';
export const LOCATION_INFO = '/location/location-information';
export const INSTAGRAM_SETTINGS = '/instagram_settings';
export const INSTAGRAM_SETTINGS_BY_LOCATION = `${INSTAGRAM_SETTINGS}/by-location`;
export const INSTAGRAM_BD_SETTINGS = '/instagram_bd_settings';
export const INSTAGRAM_BD_SETTINGS_BY_LOCATION = `${INSTAGRAM_BD_SETTINGS}/by-location`;
export const GMB_SETTINGS = '/gmb_settings';
export const YPLACE_SETTINGS = '/yplace_settings';
export const LINE_SETTINGS = '/line_settings';
export const LINE_OFFICIAL_SETTINGS = `/line_official_settings`;
export const LINE_OFFICIAL_SETTINGS_BY_LOCATION = `${LINE_OFFICIAL_SETTINGS}/by-location`;
export const SPLAN_SETTINGS = `/splan_settings`;
export const SPLAN_SETTINGS_BY_LOCATION = `${SPLAN_SETTINGS}/by-location`;
export const OWLET_SETTINGS = `/owlet_settings`;
export const OWLET_SETTINGS_BY_LOCATION = `${OWLET_SETTINGS}/by-location`;
export const UBERALL_SETTINGS = `/uberall_settings`;
export const UBERALL_SETTINGS_BY_LOCATION = `${UBERALL_SETTINGS}/by-location`;
export const ONERANK_SETTINGS = `/onerank_settings`;
export const ONERANK_SETTINGS_BY_LOCATION = `${ONERANK_SETTINGS}/by-location`;
export const HITOSARA_SETTINGS = `/hitosara_settings`;
export const HITOSARA_SETTINGS_BY_LOCATION = `${HITOSARA_SETTINGS}/by-location`;
export const GMB_SETTINGS_BY_LOCATION = `${GMB_SETTINGS}/by-location`;
export const YPLACE_SETTINGS_BY_LOCATION = `${YPLACE_SETTINGS}/by-location`;
export const LOCATION_INFORMATIONS = '/location_informations';
export const HASHTAG_TRIGGER = '/gbp_hashtag';
export const FIXED_PHRASES = '/fixed_phrases';
export const FIXED_PHRASE_BY_LOCATION = (id, serviceType) =>
  `${LOCATIONS}/${id}/fixed-phrase-by-location/${serviceType}`;
export const FIXED_REVIEW_PHRASES = '/fixed_review_phrases';
export const FIXED_REVIEW_PHRASE_BY_LOCATION = (id) =>
  `${LOCATIONS}/${id}/fixed-review-phrase-by-location`;
export const LINKED_POSTS_EXCLUSION_WORDS = '/linked_posts_exclusion_words';
export const LINKED_POSTS_REMOVE_VIOLATED_WORDS =
  '/linked_posts_remove_violated_words';
export const LINKED_POSTS_REMOVE_VIOLATED_UPDATE =
  '/linked_posts_remove_violated_update';
export const LINKED_POSTS_EXCLUSION_WORD_BY_LOCATION = (id, serviceType) =>
  `${LINKED_POSTS_EXCLUSION_WORDS}/${id}/by-location/${serviceType}`;
export const REMOVE_VIOLATED_WORD_BY_LOCATION = (id) =>
  `${LINKED_POSTS_REMOVE_VIOLATED_WORDS}/${id}`;
export const UPDATE_VIOLATED_WORD_BY_LOCATION = (id, updateViolateVal) =>
  `${LINKED_POSTS_REMOVE_VIOLATED_UPDATE}/${id}/by-location/${updateViolateVal}`;
export const REVIEW_AUTOREPLY_SETTINGS = '/review-autoreply-settings';
export const REVIEW_AUTOREPLY_SETTINGS_BY_LOCATION = (id) =>
  `${LOCATIONS}/${id}/review-autoreply-settings-by-location`;
export const FIXED_REVIEW_PHRASE_SELECT = (id) =>
  `${FIXED_REVIEW_PHRASES}/${id}/by-location`;
export const BASIC_INFO_GET = '/basic-information-by-location';
export const BASIC_INFORMATIONS = '/basic_informations';
export const PREFECTURES = '/prefectures';
export const GROUPS = '/groups';
export const BUSINESS_CATEGORY = '/business-categories';
export const BULK_UPDATE = `${BASIC_INFORMATIONS}/bulk-edit`;
export const PHOTO_CATEGORIES = '/photo-categories';
export const GMB_PHOTOS = '/gmb_photos';
export const PHOTO_BY_LOCATION = '/basic-gmb-photo-by-location';
export const REVIEWS = '/reviews';
export const SERVICES_ITEMS = '/service_items';
export const REMANDING_REQUEST = '/remanding_requests';
export const BUTTON_TYPES = '/button/types';
export const CATEGORY_TYPES = '/categories';
export const REPLY_DELETE = '/reply/delete';
export const REPLY = '/reply';
export const DASHBOARDS = '/dashboards';
export const DASHBOARDS_CSV_DOWNLOAD = '/dashboards/csv-download';
export const GMB_POST = '/gmb_posts';
export const GMB_POST_DELETE = (postId, locationId) =>
  `${GMB_POST}/${postId}/locations/${locationId}`;
export const APPROVAL_REQUEST = '/approving_requests';
export const APPROVE = '/approve';
export const REMAND = '/remand';
export const BULK = '/bulk';
export const LOCATIONS_LIST = '/location/list';
export const LOCATION_BOOKS = '/location/books';
export const LOCATION_BOOKS_LINKED = '/location/books-linked';
export const LOCATION_SERVICE_ITEM = '/location/location-with-service-items';
export const LOCATION_INSTAGRAM_CONSULT = '/location/instagram-consult';
export const SERVICE_ITEM_BY_LOCATION = '/service-items-by-location';
export const BASIC_INFORMATION_BUSINESS_CATEGORY_BY_LOCATION =
  '/basic-information-business-category-by-location';
export const ALL_ATTRIBUTES = '/all_attributes';
export const LOCATION_FACEBOOK_PAGES = (id) =>
  `${LOCATIONS}/${id}/facebook_pages`;
export const LOCATION_YPLACE_SEQUENCES = (id) =>
  `${LOCATIONS}/${id}/yplace_sequences`;
export const LOCATION_YPLACE_INITIAL_SYNC = (id) =>
  `${LOCATIONS}/${id}/initial_yplace_sync`;
export const INSTAGRAM_MEDIA_URL = 'instagram_media_url';
export const INSTAGRAM_DASHBOARD = (location, startDate, endDate) =>
  `/top/instagram?locations[]=${location}&start_date=${startDate}&end_date=${endDate}`;
export const LINE_DASHBOARD = (location, startDate, endDate) =>
  `/top/line?locations[]=${location}&start_date=${startDate}&end_date=${endDate}`;
export const GOOGLE_DASHBOARD = (location, startDate, endDate) =>
  `/top/google?locations[]=${location}&start_date=${startDate}&end_date=${endDate}`;
export const YPLACE_DASHBOARD = (location, startDate, endDate) =>
  `/top/yplace?locations[]=${location}&start_date=${startDate}&end_date=${endDate}`;
export const LOCATION_FOR_INSTAGRAM = '/location/for-instagram';
export const LOCATION_FOR_INSTAGRAM_INCLUDING_BD =
  '/location/for-instagram-including-bd';
export const LOCATION_FOR_GBP = '/location/for-gbp';
export const LOCATION_FOR_LINE = '/location/for-line';
export const LOCATION_FOR_ALL_MESSAGE = '/location/for-all-message';
export const LOCATION_FOR_INSTAGRAM_MESSAGE = '/location/for-instagram-message';
export const LOCATION_FOR_GOOGLE_MESSAGE = '/location/for-google-message';
export const LOCATION_FOR_YPLACE = '/location/for-yplace';
export const MESSAGE_THREADS = '/message/message_threads';
export const MESSAGES = '/message/messages';
export const MESSAGE_SETTING_SPAM_WORDS = 'message/setting/spam-words';
export const MESSAGE_SETTING_IMPORTANT_WORDS =
  'message/setting/important-words';
export const GENERATE_CAPTION_HASHTAGS = `/instagram/caption/generate`;
export const GET_SCHEDULE_POST = `/instagram/scheduled`;
export const GET_INSTAGRAM_STATUS = `/instagram/status`;
export const NEW_SCHEDULE_POST = `/instagram/new`;
export const UPDATE_DELETE_SCHEDULE_POST = `/instagram`;
export const GOOGLE_TRANSLATION = `/google/translation`;
export const REVIEW_COLLECTION = '/review_collection_settings';
export const REVIEW_CUSHION_TRANSLATE = '/review-cushion-translate';
export const REVIEW_COLLECTION_RATING_SUBMIT = `review/collect`;
export const REVIEW_COLLECTION_DEFAULT_IMG_URL = 'review/collect/default_image';
export const UPDATE_WIN_CMS = `/win_post`;
export const SEND_CAPTION_HASHTAG_LINE = `send-hashtag-caption-line`;
export const CUSHION_REVIEW_GENERATE = `/generate-review-from-keywords`;
export const SEND_SMS_ALL_LOCATION = (locationId, page, tierName) =>
  `locations/${locationId}/sms?page=${page}&tier=${tierName}`;
export const SMS = '/sms';
export const SMS_CATEGORIES_ALL_LOCATION = (id) =>
  `locations/${id}/sms-categories`;
export const SMS_CONTACTS_ALL_LOCATION = (id, page) =>
  `locations/${id}/sms-contacts?page=${page}`;
export const SMS_CATEGORY_CONTACTS = (id, page) =>
  `/sms-categories/${id}?showContacts=true&page=${page}`;
export const SMS_CONTACTS = '/sms-contacts';
export const SMS_CATEGORIES = '/sms-categories';
export const SMS_CSV_UP = '/sms-contact/csv-upload';
export const SMS_REPORTS = (
  id,
  reportType,
  reportSpan,
  reportTier,
  startDate,
  endDate,
) =>
  `/locations/${id}/sms-reports?type=${reportType}&span=${reportSpan}&tier=${reportTier}${
    startDate ? `&startDate=${startDate.toISOString().split('T')[0]}` : ''
  }${endDate ? `&endDate=${endDate.toISOString().split('T')[0]}` : ''}`;
export const SMS_TEMPLATES = '/sms-templates';
export const SMS_TEMPLATES_LOCATIONS = (locationId, tierName) =>
  `/locations/${locationId}/sms-templates?tier=${tierName}`;
export const CUSHION_REDIRECT_GBP = 'cushion-redirect-gbp';
export const IG_INFLUENCERS = '/ig/influencer/ig_influencers';
export const PREFERENCES_SETTINGS = '/user/preferences_settings';
export const PREFERENCES_SETTINGS_SAVE = '/user/preferences_settings/save';
export const FAVORITE_INFLUENCER_CATEGORIES = '/favorite-influencer-categories';
export const INFLUENCERS_TO_FAVORITE_LIST =
  '/ig/influencer/favorite-influencers-list';
export const GET_INFLUENCERS = '/ig/influencer/ig_influencers';
export const SAVE_FILTER_SETTINGS = '/ig/influencer/filter_settings/save';
export const DELETE_FILTER_SETTINGS = '/ig/influencer/filter_settings/delete';
export const FILTER_SETTINGS = '/ig/influencer/filter_settings';
export const GET_CSV_FAVOURITE_INFLUENCER = 'favorite-influencers/csv-download';
export const DELETE_FVOURITE_INFLUENCER = 'ig/influencer/favorite_influencers';
export const INFLUENCER_RELATED_CATEGORIES = 'ig/influecer/related-categories';
export const INFLUENCER_RELATED_REGIONS = 'ig/influecer/related-regions';
export const USER_ASSIST_PARTNER_ID = `${BASE_URL}/user-assist-partner-id`;